var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-format-list-bulleted-square")]),_vm._v(" Lista de categorias "),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-3",attrs:{"append-icon":"mdi-magnify","label":"Busca","single-line":"","hide-details":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","to":{ name: 'categoriesAdd' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Nova categoria ")],1)],1)],1)],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.categories,"item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                        name: 'categoriesEdit',
                        params: { id: item.id }
                      }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }